@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto;
}

h1 {
  font-weight: 400;
  margin: 16px 0;
  font-size: x-large;
}

h2 {
  font-size: 24px;
  font-weight: 300;
}


main {
  padding-top: 72px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 800px) {
  main {
    padding-top: 58px;
  }
}

svg {
  display: inline-block;
}

.fw > div {
  width: 100%;
}

input[type=date] {
  padding: 0px 8px;
  height: 32px;
  width: 100%;
  border: 1px solid rgb(67, 116, 166);
  border-radius: 2px;
}

input[type=date]:focus {
  outline: 0;
  border: 2px solid rgb(0, 53, 107);
}

[class*="hasError"] {
  /* .fieldGroup-146, .css-207::after, .css-184::after } */
   border-color: #B30E1B !important;
   /* border-width: 2px; */
 }

.textfield, .phone-input input {
  padding: 0px 8px;
  height: 32px;
  width: 100%;
  border: 1px solid rgb(67, 116, 166);
  border-radius: 2px;
  color: rgb(0, 53, 107);
  font-size: 14px;
  font-weight: 400;
}

.textfield:focus, .phone-input input:hover {
  border: 1px solid rgb(0, 53, 107);
}
.textfield:focus, .phone-input input:focus {
  outline: 0;
  border: 2px solid rgb(0, 53, 107);
}

.styled-multiselect > div {
  border: 1px solid rgb(67, 116, 166);
}
.styled-multiselect > div:hover {
  border: 1px solid rgb(0, 53, 107);
}
.styled-multiselect svg {
  fill: rgb(0, 53, 107);
}

.phone-input.hasError input {
  border-color: #B30E1B;
}

.slide-in {
  animation: slideIn 0.5s ease-in-out;
}
.error-in {
  animation-name: error-slide;
  animation-duration: 0.367s;
  animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  animation-fill-mode: both;
}
.fade-in {
  animation: fade .5s ease-in-out;
}
.pac-container, .pac-item {
  z-index: 10000000;
}

@keyframes error-slide{
  from {
    opacity: 0;
    transform: translate3d(0px, -20px, 0px);
    pointer-events: none;
  }
  
  to {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    pointer-events: auto;
  }
}

@keyframes slideIn{
  from {
    transform: translateY(-25%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
