.table-container {
  padding: 0 20px;
  /* background-color: rgb(243 244 246); */
}

th, td {
  padding: 6px 10px;
}

th {
  border-bottom: 2px solid #cacaca;
  text-align: left;
}

/* thead tr {
  background-color: rgb(243 244 246);
}
tr:nth-child(odd) {
  background-color: white;
} */


tr:nth-child(even) {
  background-color: #DEF2FF;
}

.table {
  width: 100%;
}

@media (max-width: 600px) {
  .form {
    grid-template-columns: 1fr 1fr;
  }
}
