.toasty {
  border-radius: 3px;
  transition: opacity 800ms linear;
  animation: 800ms fadeIn;
  display: flex;
  background-color: white;
  align-items: center;
}

.inner-toasty {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.toasty.alert {
  border: 3px solid #a00d18;
}

.toasty span {
  color: #333;
  margin: 0 2rem 0 3rem;
  font-weight: 400;
}

.toasty-container {
  opacity: 0;
  display: flex;
  flex-direction: column;
}
.toasty-container-visible {
  opacity: 1;
  position: fixed;
  z-index: 1000002;
  width: 100%;
  top: 100px;
}

.inner-toasty.toasty-right {
  justify-content: flex-end;
  margin-right: 10px;
  /* opacity: 1;
  position: absolute;
  z-index: 1000002;
  width: 100%;
  top: 150px;
  right: 50px; */

}
.toast-no-button span {
  margin: 0 3rem;
}
.toast-no-button {
  padding: 12px 0;
}
.close-toast {
  padding: 10px;
  z-index: 1000000000;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
