.menu {
  height: 34px;
  width: 34px;
  position: relative;
  transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  /* -moz-border-radius: 100%; */
  /* -webkit-border-radius: 100%; */
}

.bar {
  height: 3px;
  width: 34px;
  display: block;
  position: relative;
  background-color: #fff;
  /* -moz-border-radius: 10px; */
  /* -webkit-border-radius: 10px; */
  /* border-radius: 10px; */
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.bar {
  position: absolute;
}
.bar:nth-of-type(1) {
  top: 10px;
  -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
  -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
  -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
  -webkit-transition-delay: 0.3s, 0.1s;
  transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
  -moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
  -webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
  animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
}
.bar:nth-of-type(2) {
  bottom: 10px;
  -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
  -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
  -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
  -webkit-transition-delay: 0.3s, 0.1s;
  transition: bottom 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
  -moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
  -webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
  animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
}
.menu.open .bar:nth-of-type(1) {
  top: 15px;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
  -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
  -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
  -webkit-transition-delay: 0.1s, 0.5s;
  transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
}
.menu.open .bar:nth-of-type(2) {
  bottom: 15px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
  -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
  -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
  -webkit-transition-delay: 0.1s, 0.5s;
  transition: bottom 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
}
